import React from "react";
import { ReactComponent as EmailIcon } from "../assets/email.svg";
import { ReactComponent as WorkIcon } from "../assets/work.svg";

export default function MinimalPortfolio() {
  return (
    <div id="minimalPortfolio">
      <div className="pb8" id="minimalAbout">
        <div className="mb4">
          <div className="underline-indigo">
            <p className="title text-bold mt2">I'm Kaushik!</p>
          </div>
          <p className="mt0">
            I build software for a living. I explore tech. I love automating
            things. I primarily write TS/JS, C# and Go.
          </p>
          <div className="divider"></div>
        </div>
        <div className="flex-start mt2">
          <EmailIcon className="mr3" />
          <p className="small">kaushik.sdrt@gmail.com</p>
        </div>
        <div className="flex-start mt2">
          <WorkIcon className="mr3" />
          <p className="small">Unavailable for hire</p>
        </div>
      </div>
    </div>
  );
}
